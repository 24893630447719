<template>
  <router-view class="container-between-stickies"/>
</template>

<script>
import { AuthMixin } from '@afrigis/iris-vuex-auth-store';

export default {
  computed: {
    version: () => process.env.VUE_APP_VERSION,
  },
  mixins: [AuthMixin],
  name: 'Reporting',
};
</script>

<style>
</style>
