export default {
  computed: {
    exportData: () => [{ exportDataNotImplemented: 'true' }],
    fileNamePrefix: () => '',
    fullFileName() {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      return `${this.fileNamePrefix}-${new Date(this.dateFrom).toLocaleDateString('en-ZA', options)}-${new Date(this.dateTo).toLocaleDateString('en-ZA', options)}`;
    },
  },
};
