<template>
  <b-button
    @click="exportData"
    variant="secondary"
  >
    Export to CSV
  </b-button>
</template>

<script>

import {
  BButton,
} from 'bootstrap-vue';

export default {
  components: {
    BButton,
  },
  methods: {
    exportData() {
      const csvContent = this.transposeArrayToCsvRowContent(
        this.data,
      );
      const csvData = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', csvData);
      link.setAttribute(
        'download',
        `${this.fileName}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    transposeArrayToCsvRowContent(data) {
      let csvContent = 'data:text/csv;charset=utf-8,';
      if (data.length > 0) {
        csvContent += [
          Object.keys(data[0]).join(','),
          ...data.map((i) => Object.values(i)
            .map((value) => `"${value}"`)
            .join(',')
            .replace(/(\r\n|\n|\r)/gm, ' ')),
        ].join('\n');
      }
      return csvContent;
    },
  },
  props: {
    data: {
      default: () => [],
      required: false,
      type: Array,
    },
    fileName: {
      default: 'DataExport',
      required: false,
      type: String,
    },
  },
};
</script>
