import Axios from 'axios';

import GetterUtilities from '@/store/utilities/getters';
import MutationUtilities from '@/store/utilities/mutations';

// correct endpoint needed
const BASE_URL = `${process.env.VUE_APP_ROOT_API}/audit`;

const MUTATION_SET_DATA = 'SetData';
const MUTATION_SET_IS_LOADING = 'SetIsLoading';

const STATEVAR_DATA = 'data';
const STATEVAR_IS_LOADING = 'isLoading';

const actions = {
  Load: async (context, payload) => {
    context.commit(MUTATION_SET_IS_LOADING, true);
    context.commit(MUTATION_SET_DATA, []);
    Axios
      .get(`${BASE_URL}?startTime=${payload.startDate.toISOString()}&endTime=${payload.endDate.toISOString()}`)
      .then((response) => {
        context.commit(MUTATION_SET_DATA, response.data.result);
      })
      .catch(() => {
        throw new Error('Could not load Audit Data');
      })
      .finally(() => {
        context.commit(MUTATION_SET_IS_LOADING, false);
      });
    // try {
    //   context.commit(MUTATION_SET_IS_LOADING, true);
    //   context.commit(MUTATION_SET_DATA, []);
    //   setTimeout(() => {
    //     context.commit(MUTATION_SET_DATA, [
    //       {
    //         email: 'herm@afrigis.co.za',
    //         capability: 'Base Maps',
    //         timestamp: 1613547658,
    //         name: 'Herma',
    //         surname: 'Thulare',
    //         company: 'AfriGIS',
    //         phoneNumber: '0791111111',
    //       },
    //       {
    //         email: 'herm@afrigis.co.za',
    //         capability: 'Search',
    //         timestamp: 1613515841,
    //         name: 'Herma',
    //         surname: 'Thulare',
    //         company: 'AfriGIS',
    //         phoneNumber: '0791111111',
    //       },
    //     ]);
    //     context.commit(MUTATION_SET_IS_LOADING, false);
    //     resolve();
    //   }, 1000);
    // } catch {
    //   context.commit(MUTATION_SET_IS_LOADING, false);
    //   reject();
    // }
  },
  LoadbyEmailOrCapability: (context, payload) => new Promise((resolve, reject) => {
    if (!payload.email && !payload.capability) {
      reject(new Error('email or capability required'));
      return;
    }
    try {
      context.commit(MUTATION_SET_IS_LOADING, true);
      context.commit(MUTATION_SET_DATA, []);
      setTimeout(() => {
        context.commit(MUTATION_SET_DATA, [
          {
            email: 'herm@afrigis.co.za',
            capability: 'Base Maps',
            timestamp: 1613547658,
            name: 'herm',
            surname: 'Thulare',
            company: 'AfriGIS',
            phoneNumber: '0791111111',
          },
          {
            email: 'herm@afrigis.co.za',
            capability: 'Search',
            timestamp: 1613515841,
            name: 'Herm',
            surname: 'Thulare',
            company: 'AfriGIS',
            phoneNumber: '0791111111',
          },
        ]);
        context.commit(MUTATION_SET_IS_LOADING, false);
        resolve();
      }, 1000);
    } catch {
      context.commit(MUTATION_SET_IS_LOADING, false);
      reject();
    }
  }),
};
const getters = {
  Data: GetterUtilities.getArrayCopy(STATEVAR_DATA),
  IsLoading: GetterUtilities.get(STATEVAR_IS_LOADING),
};

const mutations = {
  [MUTATION_SET_DATA]: MutationUtilities.set(STATEVAR_DATA),
  [MUTATION_SET_IS_LOADING]: MutationUtilities.set(STATEVAR_IS_LOADING),
};

const state = {
  [STATEVAR_DATA]: [],
  [STATEVAR_IS_LOADING]: false,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
