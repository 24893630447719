<template>
  <b-container fluid>
    <b-row class="mb-2 pt-2">
      <b-col cols="12" md="8">
        <b-form inline>
          <h1 class="pr-3">
            Report
          </h1>
          <b-input-group prepend="From">
            <b-form-datepicker
              :date-format-options="dateFormat"
              locale="en-ZA"
              :max="dateTo"
              v-model="dateFrom"
            />
          </b-input-group>
          <b-input-group prepend="To">
            <b-form-datepicker
              :date-format-options="dateFormat"
              locale="en-ZA"
              :min="dateFrom"
              v-model="dateTo"
            />
          </b-input-group>
        </b-form>
      </b-col>

      <b-col class="text-left text-md-right" cols="12" md="4">
        <b-button-group>
          <filter-dropdown :filtered="isBeingFiltered">
            <float-label
              class="mt-3"
              :dispatch="false"
              fixed
              label="Capabilities"
            >
              <b-form-select
                :options="capabilityFilterItems"
                v-model="filterOnCapabilities"
              >
                <template v-slot:first>
                  <b-form-select-option
                    :value="null"
                  >
                    ALL (No filter)
                  </b-form-select-option>
                </template>
              </b-form-select>
            </float-label>
            <float-label
              class="mt-3"
              :dispatch="false"
              fixed
              label="Email"
            >
              <b-form-select
                :options="emailFilterItems"
                v-model="filterOnEmails"
              >
                <template v-slot:first>
                  <b-form-select-option
                    :value="null"
                  >
                    ALL (No filter)
                  </b-form-select-option>
                </template>
              </b-form-select>
            </float-label>
            <float-label
              class="mt-3"
              :dispatch="false"
              fixed
              label="Company"
            >
              <b-form-select
                :options="companyFilterItems"
                v-model="filterOnCompanies"
              >
                <template v-slot:first>
                  <b-form-select-option
                    :value="null"
                  >
                    ALL (No filter)
                  </b-form-select-option>
                </template>
              </b-form-select>
            </float-label>
          </filter-dropdown>
          <export-to-csv
            :data="exportData"
            :file-name="fullFileName"
          />
        </b-button-group>
      </b-col>
    </b-row>
    <b-alert
      @dismissed="alertDismissed"
      class="text-center"
      dismissible
      variant="danger"
      :show="isShowingLoaderErrorAlert"
    >
      {{ loadingErrorMessage }}
    </b-alert>
    <report-grid
      :fields="headerNames"
      :is-busy="loadingActions"
      is-responsive
      :sort-by="sortBy"
      :values="tableValues"
    >
      <template #cell(timestamp)="row">
        {{row.item.timestamp|dateTimeDisplay}}
      </template>
    </report-grid>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  BAlert,
  BButtonGroup,
  BCol,
  BContainer,
  BForm,
  BFormDatepicker,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BRow,
} from 'bootstrap-vue';

import ReportGrid from '@afrigis/bootstrap-vue-table';
import ExportToCsv from '@/components/shared/ExportToCsv.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

import exportDataBase from '@/mixins/exportData';

export default {
  components: {
    BAlert,
    BButtonGroup,
    BCol,
    BContainer,
    BForm,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BRow,
    ExportToCsv,
    FilterDropdown,
    ReportGrid,
  },
  computed: {
    ...mapGetters({
      loadingActions: 'reportStore/IsLoading',
      reportData: 'reportStore/Data',
    }),
    capabilityFilterItems() {
      const listOfCapabilities = this.reportData
        .map((rd) => rd.capability)
        .filter((rd) => rd)
        .sort();
      return [...new Set(listOfCapabilities)];
    },
    companyFilterItems() {
      const listOfCompanies = this.reportData
        .map((rd) => rd.company)
        .filter((rd) => rd)
        .sort();
      return [...new Set(listOfCompanies)];
    },
    dateFormat: () => ({
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
    emailFilterItems() {
      const listOfEmails = this.reportData
        .map((rd) => rd.email)
        .filter((rd) => rd)
        .sort();
      return [...new Set(listOfEmails)];
    },
    exportData() {
      if (this.loadingActions || !this.reportData || !this.reportData.length) {
        return [];
      }
      return this.tableValues
        .map((report) => ({
          Name: report.name,
          Surname: report.surname,
          Company: report.company,
          Email: report.email,
          Contact: report.phoneNumber,
          Capability: report.capability,
          Time: report.timestamp,
        }));
    },
    fileNamePrefix: () => 'marketing-portal-usage-report',
    headerNames: () => [
      {
        key: 'name',
        label: 'Name',
        sortable: true,
      },
      {
        key: 'surname',
        label: 'Surname',
        sortable: true,
      },
      {
        key: 'company',
        label: 'Company',
        sortable: true,
      },
      {
        key: 'email',
        label: 'Email',
        sortable: true,
      },
      {
        key: 'phoneNumber',
        label: 'Contact No.',
        sortable: true,
      },
      {
        key: 'capability',
        label: 'Capability',
        sortable: true,
      },
      {
        key: 'timestamp',
        label: 'Time',
        sortable: true,
      },
    ],
    isBeingFiltered() {
      return this.filterOnCapabilities
        || this.filterOnEmails
        || this.filterOnCompanies;
    },
    tableValues() {
      if (this.loadingActions || !this.reportData) {
        return [];
      }

      return this.reportData
        .filter((rd) => !this.filterOnCapabilities
          || this.filterOnCapabilities === rd.capability)
        .filter((rd) => !this.filterOnEmails
          || this.filterOnEmails === rd.email)
        .filter((rd) => !this.filterOnCompanies
          || this.filterOnCompanies === rd.company);
    },
    uniqueActors() {
      if (this.loadingActions || !this.reportData) {
        return [];
      }
      return [...new Set(this.reportData.map((rd) => rd.userId))]
        .map((actorId) => ({
          email: (this.IsLoadingUsers || !this.Users || !this.Users.length)
            ? actorId
            : this.Users.find((u) => u.id === actorId)?.email,
          id: actorId,
        }))
        .sort((a, b) => ((a.email < b.email) ? -1 : 1));
    },
    uniqueActions() {
      if (this.loadingActions || !this.reportData) {
        return [];
      }
      return [...new Set(this.reportData.map((rd) => rd.category))].sort();
    },
  },
  data() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return {
      dateFrom: new Date(now - 7 * 24 * 60 * 60 * 1000),
      dateTo: now,
      filterOnCapabilities: null,
      filterOnCompanies: null,
      filterOnEmails: null,
      sortBy: 'timestamp',
    };
  },
  filters: {
    dateTimeDisplay(val) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      return new Date(val).toLocaleDateString('en-ZA', options);
    },
  },
  methods: {
    ...mapActions({
      LoadActions: 'reportStore/Load',
    }),
    refreshData() {
      const endDate = new Date(this.dateTo);
      endDate.setDate(endDate.getDate() + 1);

      this.LoadActions({
        startDate: new Date(this.dateFrom),
        endDate,
      });
    },
  },
  mixins: [
    exportDataBase,
  ],
  mounted() {
    this.refreshData();
  },
  name: 'Reporting',
  watch: {
    dateFrom() {
      this.refreshData();
    },
    dateTo() {
      this.refreshData();
    },
  },
};
</script>

<style scoped></style>
